import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAnimate, stagger, usePresence } from 'framer-motion';

import { USER } from '../../../IWWJsCode';
import CartButton from './_CartButton';
import { useTranslation } from 'react-i18next';

export default function ChapterBox({ chapter, key }) {
  let { isUserChapter } = USER();
  const { t } = useTranslation();

  const [isPresent, safeToRemove] = usePresence();
  const [scope, animate] = useAnimate();

  useEffect(() => {
    if (isPresent) {
      const enterAnimate = async () => {
        await animate(scope.current, { opacity: [0, 1] }, { duration: 1, delay: stagger(0.5) });
      };
      enterAnimate();
    } else {
      const exitAnimate = async () => {
        await animate(scope.current, { opacity: [1, 0] }, { duration: 1, delay: stagger(0.5) });
        safeToRemove();
      };
      exitAnimate();
    }
  });

  const isOpen = isUserChapter(chapter);

  return (
    <>
      <div ref={scope} key={key} className='card-body text-center'>
        <h3 className='card-title h5 position-relative'>
          {isOpen ? (
            <>
              <i className='fas fa-lock-open text-dark mx-1'></i>
            </>
          ) : !chapter.isFree ? (
            <>
              <i className='fas fa-lock text-dark mx-1'></i>
            </>
          ) : (
            ''
          )}

          {chapter.name}
          <span className='position-absolute top-0 start-0 translate-middle badge rounded-pill text-bg-light'>{chapter.sort}</span>
        </h3>
        <div className='card-text' dangerouslySetInnerHTML={{__html: chapter.description}}></div>

        <p className='card-text'>
          {chapter.videosCount && chapter.videosCount > 0 ? (
            <span className='mx-3 btn btn-light'>
              <i className='fas fa-video mx-1'></i>
              {t('Videos')}
              <span className='badge text-bg-light mx-1'>{chapter.videosCount}</span>
            </span>
          ) : (
            ''
          )}
          {chapter.booksCount && chapter.booksCount > 0 ? (
            <span className='mx-3 btn btn-light'>
              <i className='fas fa-book mx-1'></i>
              {t('Books')}
              <span className='badge text-bg-light mx-1'>{chapter.booksCount}</span>
            </span>
          ) : (
            ''
          )}
        </p>
        {chapter.isFree || isOpen ? (
          <>
            <Link className={'btn mt-3 btn-outline-success'} to={'/Chapter/' + chapter.id}>
              {t('View media materials')} <i class='fas fa-arrow-right text-success mx-2'></i>
            </Link>
          </>
        ) : (
          ''
        )}
        {!chapter.isFree && !isOpen ? (
          <>
            {chapter.price > 0 ? (
              <strong className='mx-1'>
                {chapter.price} {chapter.currency}
              </strong>
            ) : (
              ''
            )}
            {/* <i className='fas fa-lock text-dark mx-1'></i> */}
            <CartButton paymentType={'Payment'} price={chapter.price} type='Chapter' id={chapter.id} />
          </>
        ) : (
          ''
        )}
      </div>
    </>
  );
}
