/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import { USER, APIURL, CART, LANG, Loader, SetTitle } from '../../IWWJsCode';
import { useTranslation } from 'react-i18next';
import CartButton from '../Materials/Partials/_CartButton';

export default function Cart() {
  let [cartItemsData, setCartItemsData] = useState();
  let [termsAgree, setTermsAgree] = useState(false);
  let [total, setTotal] = useState({});
  let [currency, setCurrency] = useState([]);

  let { cartItems, emptyCart, removeItem, UpdateItemPayment, toggleItemInner } = CART();
  const { user } = USER();
  const { lang, Dateformat } = LANG();
  const { loading, setLoading } = Loader();
  let [message, setMessage] = useState({ success: null, message: null });
  let [submitting, setSubmitting] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    getCartItemsData(cartItems, lang);
  }, [cartItems, lang]);

  function UpdateData() {
    getCartItemsData(cartItems, lang);
  }

  async function getCartItemsData(items, lng) {
    setLoading(true);
    console.log('cartItems , cartItems.length', items, items.length);
    if (items && items.length) {
      fetch(APIURL(lng + '/payments/cart'), {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(items),
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.cartData) setCartItemsData(json.cartData);
          if (json.total) setTotal(json.total);
          if (json.currency) setCurrency(json.currency);
          if (json.total && json.currency)
            SetTitle('Checkout (' + json.total.total + ' ' + json.currency + ')');
          setLoading(false);
          console.log('cartItemsData', cartItemsData);
        });
    } else {
      setLoading(false);
      setCartItemsData(null);
    }
  }

  async function checkout(items, lng) {
    setSubmitting(true);
    fetch(APIURL(lng + '/payments/pay'), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ cartItems: items }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.message) setMessage(json.message);
        // if (json.checkoutApiResponse) setCheckoutApiResponse(json.checkoutApiResponse);
        if (json.redirectUrl) window.location.href = json.redirectUrl;
      });
    setSubmitting(false);
    console.log('cartItemsData', cartItemsData);
  }

  return (
    <>
      <section>
        <div class='container'>
          <div class='row'>
            <div class='col-sm-12'>
              <h1 class='sub-heading'>{t('Shopping Cart')}</h1>
              {/* <button type='button' className='btn btn-sm btn-outline-dark float-end'
                onClick={(e) => { emptyCart(); }}>
                {t('Clear')} <i className='fas fa-times'></i>
              </button> */}
            </div>
          </div>

          <div class='row'>
            <div class='col-xl-8'>
              {cartItemsData && cartItemsData.length > 0 ? (
                <>
                  {cartItemsData.map((el, i) => (
                    <>
                      <div className={'card border shadow-none ' + (el.isParentInCart ? 'opacity-75' : '')}>
                        <div class='card-body'>
                          <div class='d-flex align-items-start border-bottom pb-3'>
                            {el.Image ? (
                              <>
                                <div class='me-4'>
                                  <img src={el.Image} class='avatar-lg rounded' />
                                </div>
                              </>
                            ) : (
                              ''
                            )}
                            <div class='flex-grow-1 align-self-center overflow-hidden'>
                              <div>
                                <h5 class='text-truncate font-size-18'>
                                  <Link to={(el.type == 'Project' ? '/project/' : '/Course/') + el.courseId} class='nav-link'>
                                    {el.name}
                                  </Link>
                                </h5>
                                <p class='mb-0 mt-1'>
                                  <span class='fw-medium'>{t(el.type)}</span> {el.InstructorName}
                                </p>
                              </div>
                            </div>
                            <div class='flex-shrink-0 ms-2'>
                              <ul class='list-inline mb-0 font-size-16'>
                                <li class='list-inline-item'>
                                  <button
                                    onClick={(e) => {
                                      removeItem(el.id);
                                    }}
                                    class='text-muted btn px-1'>
                                    <i class='far fa-trash-alt text-danger'></i>
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </div>

                          {el.note || el.error ? (
                            <>
                              <ul class='list-unstyled'>
                                {el.note ? (
                                  <li>
                                    <span className='text-wrap m-1 text-success'>{el.note}</span>
                                  </li>
                                ) : (
                                  ''
                                )}
                                {el.error ? (
                                  <li>
                                    <span className={'text-wrap m-1 text-' + (el.isParentInCart ? 'warning' : 'danger')}>{el.error}</span>
                                  </li>
                                ) : (
                                  ''
                                )}
                              </ul>
                            </>
                          ) : (
                            ''
                          )}

                          <div>
                            <div class='row'>
                              {el.paymentPlan ? (
                                <>
                                  <div class='col-md-4'>
                                    <label class={'btn mt-3 w-100 btn-outline-' + (el.paymentType == 'Payment' ? 'warning' : 'secondary')} for={'PaymentPlan_Payment_' + el.id}>
                                      <div class='form-check'>
                                        <input
                                          class='form-check-input'
                                          type='radio'
                                          name={'Payment_Plan_' + el.id}
                                          checked={el.paymentType == 'Payment'}
                                          id={'PaymentPlan_Payment_' + el.id}
                                          onClick={() => {
                                            UpdateItemPayment(el.id, 'Payment');
                                            UpdateData();
                                          }}
                                        />
                                        <span class='form-check-label text-start'>{t('One Time Payment')}</span>
                                      </div>
                                      {/* <p class="text-muted text-start mb-2">One Time Payment</p> */}
                                      <h5 class='mb-0 mt-2'>
                                        {el.pricePefore ? (
                                          <span class='text-muted me-2'>
                                            <del class='font-size-16 fw-normal'>
                                              {el.pricePefore.toFixed(1)} {el.currency}
                                            </del>
                                          </span>
                                        ) : (
                                          ''
                                        )}
                                        <p className={'mx-1' + (el.isParentInCart ? ' text-decoration-line-through' : '')}>
                                          {el.price.toFixed(1)} {el.currency}
                                        </p>
                                      </h5>
                                    </label>
                                  </div>
                                  <div class='col-md-8'>
                                    <div class={'btn d-flex justify-content-between mt-3 w-100 btn-outline-' + (el.paymentType === 'Installment' ? 'warning' : 'secondary')}>
                                      <div>
                                        <label for={'PaymentPlan_Installment_' + el.id} class='form-check'>
                                          <input
                                            class='form-check-input'
                                            type='radio'
                                            name={'Payment_Plan_' + el.id}
                                            checked={el.paymentType === 'Installment'}
                                            id={'PaymentPlan_Installment_' + el.id}
                                            onClick={() => {
                                              UpdateItemPayment(el.id, 'Installment');
                                              UpdateData();
                                            }}
                                          />
                                          <span class='form-check-label text-start'>{t('Pay today')}</span>
                                        </label>
                                        {/* <p class="text-muted text-start mb-2">Pay today</p> */}
                                        <h5>
                                          {el.paymentPlan.Base.toFixed(1)} {el.currency}
                                        </h5>
                                      </div>
                                      <div>
                                        <p class='text-muted text-start mb-2'>And {el.paymentPlan.CollectionPayments.length} Installments</p>
                                        <div className='d-inline-flex'>
                                          {el.paymentType === 'Installment' ? (
                                            <>
                                              <ul className='list-group'>
                                                {el.paymentPlan.CollectionPayments.map((cp, cpIndex) => (
                                                  <>
                                                    <li key={cpIndex} className={'list-group-item text-start'}>
                                                      <div class='form-check'>
                                                        <input class='form-check-input' type='checkbox' id={cp.Id} checked={cartItems.find((i) => i.id == el.id)?.inner?.some((i) => i.id === cp.Id) ? true : false} />
                                                        <label
                                                          class='form-check-label'
                                                          for={cp.Id}
                                                          onClick={() => {
                                                            toggleItemInner(el.id, { id: cp.Id, type: 'CourseInstallment', paymentType: 'Installment' });
                                                          }}>
                                                          {Dateformat(new Date(Date.parse(cp.DueDate)))}
                                                          <span className='badge text-bg-warning'>
                                                            {cp.Amount.toFixed(1)} {el.currency}
                                                          </span>
                                                        </label>
                                                      </div>
                                                    </li>
                                                  </>
                                                ))}
                                              </ul>
                                            </>
                                          ) : (
                                            ''
                                          )}

                                          {/* 
                                         
                                          <select class="form-select form-select-sm w-xl">
                                            <option value="1">One-Time Payment</option>
                                            <option value="2" selected="">2</option>
                                            <option value="3">3</option>
                                            <option value="4">4</option>
                                            <option value="5">5</option>
                                            <option value="6">6</option>
                                            <option value="7">7</option>
                                            <option value="8">8</option>
                                          </select> 
                                        */}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div class='col-md-4'>
                                    <div class='mt-3'>
                                      <p class='text-muted mb-2'>{t('Price')}</p>
                                      <h5 class='mb-0 mt-2'>
                                        {el.pricePefore && el.pricePefore > el.price ? (
                                          <span class='text-muted me-2'>
                                            <del class='font-size-16 fw-normal'>
                                              {el.pricePefore.toFixed(1)} {el.currency}
                                            </del>
                                          </span>
                                        ) : (
                                          ''
                                        )}
                                        <p className={'mx-1' + (el.isParentInCart ? ' text-decoration-line-through' : '')}>
                                          {el.price.toFixed(1)} {el.currency}
                                        </p>
                                      </h5>
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  ))}
                </>
              ) : (
                <>
                  {!loading ? (
                    <div>
                      <h3 className='text-center text-muted text-secondary'>
                        {t('Empty Cart')} <i className='fas fa-cart-arrow-down mx-2'></i>
                      </h3>
                    </div>
                  ) : (
                    ''
                  )}
                </>
              )}
            </div>

            <div class='col-xl-4 total-card'>
              <div class='m-0 mb-md-2 mt-md-5'>
                <div class='card border shadow-none'>
                  <div class='card-header bg-transparent border-bottom py-3 px-4'>
                    <h5 class='font-size-16 mb-0'>
                      {t('Order Summary')} <span class='float-end'>{cartItems.length}</span>
                    </h5>
                  </div>
                  <div class='card-body'>
                    <div class='table-responsive'>
                      <table class='table mb-0'>
                        {total && currency.length === 1 ? (
                          <tbody>
                            {total.discount ? (
                              <tr>
                                <td>{t('Discount')} : </td>
                                <td class='text-end'>-{total.discount}</td>
                              </tr>
                            ) : (
                              ''
                            )}

                            <tr class='bg-light'>
                              <th>{t('Total')} :</th>
                              <td class='text-end'>
                                <span class='fw-bold'>
                                  {total.total.toFixed(2)} {currency[0]}
                                </span>
                              </td>
                            </tr>
                          </tbody>
                        ) : (
                          ''
                        )}
                      </table>
                    </div>
                  </div>
                </div>
              </div>

              <div class='d-flex justify-content-between'>
                {cartItemsData && currency.length === 1 && total?.total && cartItemsData.length > 0 ? (
                  <>
                    {message && message.message ? (
                      <div className={'badge ' + (message.success ? 'bg-success' : 'bg-warning text-dark')}>
                        <p>{message.message}</p>
                      </div>
                    ) : (
                      ''
                    )}
                    {user && user.Active ? (
                      <>
                        {currency.length > 1 ? (
                          <>
                            <div class='alert alert-damger text-start w-100' role='alert'>
                              <p className='text-danger fw-bold'>
                                {t('Cart contains many items with different currencies, make cart items with same currency only.')}
                                {currency.map((el) => (
                                  <>
                                    <sub className='mx-2'>{el},</sub>
                                  </>
                                ))}
                              </p>
                            </div>
                          </>
                        ) : (
                          ''
                        )}

                        {cartItemsData.some((i) => i.error && i.error !== '') ? (
                          <>
                            <div class='alert alert-light text-start w-100' role='alert'>
                              <h5 class='alert-heading fw-bold'>Please remove this items from cart to complete the payment</h5>
                              {cartItemsData.map((el) =>
                                el.error ? (
                                  <>
                                    <h5>{el.name}</h5>
                                    <p>{el.error}</p>
                                    {el.courseId && cartItems.find((i) => i.id === el.courseId) ? (
                                      <>
                                        <button
                                          onClick={(e) => {
                                            removeItem(el.courseId);
                                          }}
                                          class='btn btn-outline-danger btn-small m-2'>
                                          <i class='far fa-trash-alt'></i> {t('No, Remove the course instead')}
                                        </button>
                                      </>
                                    ) : (
                                      ''
                                    )}

                                    <button
                                      onClick={(e) => {
                                        removeItem(el.id);
                                      }}
                                      class='btn btn-danger m-2'>
                                      <i class='far fa-trash-alt'></i> {t('Ok, Remove')} {t(el.type)}
                                    </button>
                                    <hr />
                                  </>
                                ) : (
                                  ''
                                )
                              )}
                            </div>
                          </>
                        ) : (
                          <>
                            <div>
                              <Link to='/terms' className='btn btn-light mt-2'>
                                <i class='fas fa-scroll me-2 text-primary'></i>
                                {t('Read Terms and conditions')}
                              </Link>

                              <div className='form-check mb-4'>
                                <input
                                  className='form-check-input'
                                  type='checkbox'
                                  id='termsAgreement'
                                  onChange={(e) => {
                                    setTermsAgree(!termsAgree);
                                  }}
                                />
                                <label className={'form-check-label'} style={{ cursor: 'pointer' }} htmlFor='termsAgreement'>
                                  {t('I have read and agree to the Terms and conditions')}
                                </label>
                              </div>

                              <button
                                type='button'
                                onClick={(e) => {
                                  checkout(cartItems, lang);
                                }}
                                disabled={!termsAgree || submitting}
                                class='btn btn-success float-end btn-lg'>
                                <i className='fas fa-circle-notch me-1 fa-spin' style={{ display: submitting ? 'block' : 'none' }}></i>
                                {t('Checkout')}
                                <i class='far fa-credit-card ms-2'></i>
                              </button>
                            </div>
                          </>
                        )}
                      </>
                    ) : (
                      <Link className='btn btn-dark mt-2 w-100 btn-lg' to={'/login?rurl=/Checkout'}>
                        <i class='fas fa-sign-in-alt mr-2'></i> {t('Login and complete purchase')}
                      </Link>
                    )}
                  </>
                ) : (
                  <>
                    {(!cartItems || cartItems.length === 0) && loading === false ? (
                      <div class='alert alert-light text-start' role='alert'>
                        <h4 class='alert-heading'>{t('Cart is empty')}</h4>
                        <p>{t('Please add a course or semester to proceed with payment')}.</p>
                        <hr />
                        <Link to='/Courses' className='btn text-light btn-warning btn-lg'>
                          <i class='fas fa-search mx-1'></i> {t('Courses')}
                        </Link>
                      </div>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </div>
            </div>
          </div>

          <div class='row my-4'>
            <div class='col-xl-8'>
              <div class='row'>
                <div class='col-sm-6'>
                  <Link to='/Courses' class='btn btn-link text-muted'>
                    <i class='fas fa-arrow-left me-1'></i> {t('Continue browsing courses')}
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* <div class="row ">

            <div class="col-md-12">

              {cartItemsData && cartItemsData.length > 0 ? (
                <>
                  <div class="table-wrap">
                    <table class="table ">
                      <thead class="thead-primary">
                        <tr>
                          <th>{t('Course Name')}</th>
                          <th>{t('Type')}</th>
                          <th>{t('Price')}</th>
                          <th>{t('Remove')}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {cartItemsData.map((el, i) => (
                          <>
                            <tr key={i} className={(el.isParentInCart ? 'opacity-75' : '')}>
                              <td>
                                <div class="email">
                                  <span>{el.name}</span>
                                  <ul class="list-unstyled">
                                    {el.note ? <li><span className='text-wrap m-1 text-success'>{el.note}</span></li> : ''}
                                    {el.error ? <li><span className={'text-wrap m-1 text-' + (el.isParentInCart ? 'warning' : 'danger')}>{el.error}</span></li> : ''}
                                  </ul>
                                </div>
                              </td>
                              <td className={el.type}>
                                <span>{el.type}</span>
                              </td>
                              <td>
                                {el.pricePefore ? (
                                  <>
                                    <span className='text-decoration-line-through'>
                                      {el.pricePefore ? el.pricePefore.toFixed(1) : '0.0'}
                                      <span className='mx-1'>{el.currency}</span>
                                    </span>
                                  </>
                                ) : (
                                  ''
                                )}
                                <strong className={'mx-1' + (el.isParentInCart ? ' text-decoration-line-through' : '')}>
                                  {el.price.toFixed(1)} {el.currency}
                                </strong>
                              </td>
                              <td class="close">
                                <span className='mx-1' onClick={(e) => { removeItem(el.id); }}>
                                  <i class="far fa-times-circle"></i>
                                </span>
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                      {total && currency.length == 1 && total > 0 ? (
                        <tfoot>
                          <tr>
                            <td colspan="3">{t('Total')}</td>
                            <td>{total.total.toFixed(2)} {currency[0]}</td>
                          </tr>
                        </tfoot>
                      ) : ('')}

                    </table>
                    <div class="d-flex justify-content-between">
                      {cartItemsData && currency.length == 1 && total && cartItemsData.length > 0 ? (
                        <>
                          {message && message.message ? (
                            <div className={'badge ' + (message.success ? 'bg-success' : 'bg-warning text-dark')}>
                              <p>{message.message}</p>
                            </div>
                          ) : ('')}
                          {user && user.Active ? (
                            <>
                              {cartItemsData.some((i) => (i.error && i.error !== '') || (i.note && i.note != '')) ? (
                                <>
                                  <div class='alert alert-light text-start w-100' role='alert'>
                                    {cartItemsData.map(el => (
                                      el.error ? <>
                                        <h5 class='alert-heading fw-bold'>Please remove the {el.type} from cart to complete the payment</h5>
                                        <p>{el.name}</p>
                                        <p>{el.error}</p>
                                        <hr />
                                      </> : ''
                                    ))}
                                  </div>
                                </>
                              ) : (
                                <>
                                  <div className='form-check form-check-inline text-start'>
                                    <input
                                      className='form-check-input'
                                      type='checkbox'
                                      id='termsAgreement'
                                      onChange={(e) => { setTermsAgree(!termsAgree); }}
                                    />
                                    <label className={'form-check-label'} style={{ cursor: 'pointer' }} htmlFor='termsAgreement'>
                                      {t('I agree to')}
                                    </label>{' '}
                                    <Link href='/terms' className=''>
                                      {t('the terms and conditions')}
                                    </Link>{' '}
                                    {t('and')}{' '}
                                    <Link href='/policy' className=''>
                                      {t('Usage Policies')}.
                                    </Link>
                                  </div>
                                  <button
                                    type='button'
                                    onClick={(e) => { checkout(cartItems, lang); }}
                                    disabled={!termsAgree}
                                    className='btn btn-outline-success mt-2 w-100 btn-lg'>
                                    {t('Checkout')}
                                    <span className='m-2'>
                                      {total.total.toFixed(2)} {currency[0]}
                                    </span>
                                    <i className='fas fa-credit-card mx-1'></i>
                                    <i className='fas fa-circle-notch mx-1 fa-spin float-end' style={{ display: submitting ? 'block' : 'none' }}></i>
                                  </button>
                                </>
                              )}
                            </>
                          ) : (
                            <Link className='btn btn-dark mt-2 w-100 btn-lg' to={'/login?rurl=/Checkout'}>
                              <i class="fas fa-sign-in-alt mr-2"></i> {t('Login and complete purchase')}
                            </Link>
                          )}
                        </>
                      ) : (
                        <>
                          {(!cartItems || cartItems.length === 0) && loading === false ? (
                            <div class='alert alert-light text-start' role='alert'>
                              <h4 class='alert-heading'>{t('Cart is empty')}</h4>
                              <p>{t('Please add a course or semester to proceed with payment')}.</p>
                              <hr />
                              <Link to='/Courses' className='btn btn-light'>
                                {t('Courses')}
                              </Link>
                            </div>
                          ) : (
                            ''
                          )}
                          {currency.length > 1 ? <>
                            <p className='text-danger fw-bold'>
                              {t('It is not possible to pay more than one type of currency in one payment, please pay one first and then the other. ')}
                              {currency.map((el) => (<><sub className='mx-2'>{el},</sub></>))}
                            </p>
                          </> : ''}
                        </>
                      )}

                    </div>
                  </div>
                </>
              ) : (
                <>
                  {!loading ? (
                    <div>
                      <h3 className='text-center text-muted text-secondary'>
                        {t('Empty Cart')} <i className='fas fa-cart-arrow-down mx-2'></i>
                      </h3>
                    </div>
                  ) : (
                    ''
                  )}
                </>
              )}
            </div>
          </div> */}
        </div>
      </section>
    </>
  );
}
