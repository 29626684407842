import { React, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { USER, APIURL, Loader, LANG, GET_IMAGE_URL, SetTitle } from '../../IWWJsCode';
import { useTranslation } from 'react-i18next';
import packageJson from "../../../package.json";

export default function Profile() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  let [reSendLoading, setReSendLoading] = useState(false);
  const { user, setUser, getUser, loadingUser } = USER();
  const { Dateformat, currency } = LANG();

  let { loading, setLoading } = Loader();
  let [reSend, setReSend] = useState(false);

  useEffect(() => {
    caching();
  });

  function caching() {
    let version = localStorage.getItem('version');
    if (version != packageJson.version) {
      if ('caches' in window) {
        caches.keys().then((names) => {
          // Delete all the cache files
          names.forEach(name => {
            caches.delete(name);
          })
        });

        // Makes sure the page reloads. Changes are only visible after you refresh.
        window.location.reload(true);
      }

      localStorage.clear();
      localStorage.setItem('version', packageJson.version);
    }
  };

  useEffect(() => {
    // setLoading(true);
    CheckUser();
    // setLoading(false);
  }, [user]);

  useEffect(() => {
    if (!user)
      getUser();
    SetTitle("My Profile");
  }, []);

  function CheckUser() {
    if (!user)
      navigate('/login?rurl=/Profile');
  }

  async function Logout(e) {
    e.preventDefault();
    setLoading(true);

    fetch(APIURL('auth/Logout'), {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
    }).finally(() => {
      setLoading(false);
      setUser(null);
      navigate('/login');
    });


  }

  async function resendConfirmationLink(e) {
    e.preventDefault();
    setReSendLoading(true);
    await axios.post(APIURL('auth/resendConfirmationLink'));
    setReSend(true);
    setReSendLoading(false);
  }

  return user ? (
    <>
      <section class='my-5'>
        <div class='container'>
          <div class='row'>
            <div class='col-md-8'>
              <div class='row g-3 mt-2'>
                <div class='col-md-4 col-sm-6 col-12'>
                  <div class='card card-Bdf shadow'>
                    <div class='card-header'>
                      <h5 class='colors text-center'>{t('My Courses')}</h5>
                    </div>
                    <div class='layer-Bdf'>
                      <i class='fas fa-book'></i>
                    </div>
                    <div class='card-body video-text bg-light text-center'>
                      <Link to='/my-courses' class='btn btn-outline-warning'>
                        {t('Go to Courses')}
                      </Link>
                    </div>
                  </div>
                </div>

                <div class='col-md-4 col-sm-6 col-12'>
                  <div class='card card-Bdf shadow'>
                    <div class='card-header'>
                      <h5 class='colors text-center'>{t('My Orders')}</h5>
                    </div>
                    <div class='layer-Bdf'>
                      <i class='far fa-credit-card'></i>
                    </div>
                    <div class='card-body video-text bg-light text-center'>
                      <Link to='/my-orders' class='btn btn-outline-warning'>
                        {t('Go to Orders')}
                      </Link>
                    </div>
                  </div>
                </div>

                {user.Student ? (
                  <div class='col-md-4 col-sm-6 col-12'>
                    <div class='card card-Bdf shadow'>
                      <div class='card-header'>
                        <h5 class='colors text-center'>{t('My Projects')}</h5>
                      </div>
                      <div class='layer-Bdf'>
                        <i class='fas fa-tasks'></i>
                      </div>
                      <div class='card-body video-text bg-light text-center'>
                        <Link to='/my-projects' class='btn btn-outline-warning'>
                          {t('Go to Projects')}
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}

                {user.Manager ? (
                  <div class='col-md-4 col-sm-6 col-12'>
                    <div class='card card-Bdf shadow'>
                      <div class='card-header'>
                        <h5 class='colors text-center'>{t('Instructor Dashboard')}</h5>
                      </div>
                      <div class='layer-Bdf'>
                        <i class='far fa-chart-bar'></i>
                      </div>
                      <div class='card-body video-text bg-light text-center'>
                        <a href='/InstructorManage' class='btn btn-outline-warning'>
                          {t('Go to Dashboard')}
                        </a>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}

                {user.installments && user.installments?.installments.length > 0 ? (
                  <div class='col-md-6 col-12'>
                    <div class='card card-Bdf shadow'>
                      <div class='card-header'>
                        <h5 class='colors text-center'>
                          <i class='far fa-calendar-check mx-1'></i> {t('My Installments')}
                        </h5>
                      </div>
                      {/* <div class="layer-Bdf">
                      <i class="far fa-calendar-check"></i>
                    </div> */}
                      <div class='card-body video-text bg-light text-center'>
                        {user.installments?.info.length ? (
                          /* ?.filter(i => i.RestMustPayAmount > 0) */
                          <table className='table table-bordered table-sm '>
                            <thead>
                              <tr>
                                <td>{t('Total')}</td>
                                <td>{t('Paid')}</td>
                                <td>{t('Due')}</td>
                                <td>{t('Remaining')}</td>
                              </tr>
                            </thead>
                            <tbody>
                              {user.installments?.info.map((el, indx) => (
                                <>
                                  <tr key={indx}>
                                    <td>{el.TotalAmount}</td>
                                    <td>{el.TotalPaid}</td>
                                    <td className={el.RestMustPayAmount > 0 ? 'text-danger' : ''}>{el.RestMustPayAmount}</td>
                                    <td>{el.RestPayAmount}</td>
                                  </tr>
                                  {el.RestMustPayAmount > 0 ? '' : ''}
                                </>
                              ))}
                            </tbody>
                          </table>
                        ) : (
                          ''
                        )}
                        <Link to='/my-installments' class='btn btn-outline-warning'>
                          {t('Go to installments')}
                        </Link>
                      </div>
                    </div>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
            <div class='col-md-4'>
              <div class='card card-h100 card-info border-0 shadow my-3'>
                <div class='card-header d-flex justify-content-between align-items-center'>
                  <div class='d-flex align-items-center justify-content-center'>
                    <img src={GET_IMAGE_URL(user.Image)} class='samll-img' />
                    <span class='text-decoration-none'>{user.Name}</span>
                    <a href='#'>
                      <i class='far fa-edit' data-bs-toggle='modal' data-bs-target='#userDataModal'></i>
                    </a>
                  </div>
                </div>
                <div class='card-body prof-card'>
                  <ul class='list-unstyled'>
                    <li>
                      <span class='text-decoration-none color'>
                        <strong>{t('Email')} :</strong> {user.Email}
                        {!user.EmailConfirmed && !reSend ? (
                          <>
                            <button
                              type='button'
                              className={'btn btn-warning' + (reSendLoading ? ' disabled' : '')}
                              disabled={reSendLoading ? 'disabled' : ''}
                              onClick={(e) => {
                                resendConfirmationLink(e);
                              }}>
                              {reSendLoading ? <i className='fas fa-circle-notch mx-1 fa-spin'></i> : <i className='fas mx-1 fa-redo-alt'></i>}
                            </button>
                          </>
                        ) : reSend ? (
                          <span className='btn btn-light'>
                            <i className='fas fa-check text-success'></i>
                          </span>
                        ) : (
                          <></>
                        )}
                      </span>
                    </li>
                    <li>
                      <span class='text-decoration-none color'>
                        <strong>{t('Mobile')} :</strong>
                        {user.Phone}
                      </span>
                    </li>
                  </ul>
                </div>

                <div class='card-footer d-flex justify-content-center'>
                  <button
                    type='button'
                    className={'btn btn-outline-danger w-50' + (loading ? ' disabled' : '')}
                    disabled={loading ? 'disabled' : ''}
                    onClick={(e) => {
                      Logout(e);
                    }}>
                    {loading ? <i className='fas fa-circle-notch fa-2x fa-spin'></i> : t('Logout')}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <div className='login-page'>
        <div className='container'>
          <div className='row'>
            <div className='col-md-8'>
              <SecLoader type={'cart'} />
              {user.coursesRender && user.coursesRender.length ? (
                <ul class='list-group mt-3'>
                  <li className='list-group-item bg-dark text-light d-flex justify-content-between align-items-start'>
                    <h4>
                      <i className='fas fa-compact-disc mx-2'></i> {t('My Courses')}
                    </h4>
                  </li>
                  {user.coursesRender.map((course, sindex) => (
                    <li key={sindex} className='list-group-item d-flex justify-content-between align-items-start'>
                      <div class='ms-2 me-auto'>
                        <div class='fw-bold'>{course.name}</div>
                      </div>
                      <NavLink key={sindex} className='btn btn-outline-success' to={'/course/' + course.id}>
                        {t('Go to Course')}
                      </NavLink>
                    </li>
                  ))}
                </ul>
              ) : (
                ''
              )}
              {orders && orders.length ? (
                <ul class='list-group mt-3'>
                  <li className='list-group-item bg-dark text-light d-flex justify-content-between align-items-start'>
                    <h4>
                      <i className='fas fa-file-invoice-dollar mx-2'></i> {t('My Orders')}
                    </h4>
                    <div
                      className='btn btn-outline-light'
                      onClick={(e) => { GetOrders(); }}>
                      {t('Check Recent Payments')}
                    </div>
                  </li>
                  {orders.map((order, sindex) => (
                    <li key={sindex} className='list-group-item d-flex justify-content-between align-items-start'>
                      <div class='col-4'>
                        <p class='fw-bold'>{order.date}</p>
                      </div>
                      <div className='col-4'>
                        {'(' + order.items + ')'} {t('Items')}
                      </div>
                      <div className='col-4'>
                        <Link to={'/payment-result?id=' + order.id} className={'btn w-100 btn-outline-' + (order.paid ? 'success' : 'danger')}>
                          {!order.paid ? t('Payment verification') : t('Receipt')} {financialPrice(order.amount)} {currency}
                          <i className='fas fa-file-invoice mx-1'></i>
                        </Link>
                        {!order.paid ? <p className='text-info'>{t('Re-check that the payment has been completed')}</p> : ''}
                      </div>
                    </li>
                  ))}
                </ul>
              ) : (
                ''
              )}
            </div>
          </div>
        </div>
      </div> */}

      <div class='modal fade' id='userDataModal' tabindex='-1'>
        <div class='modal-dialog modal-dialog-centered'>
          <div class='modal-content'>
            <div class='modal-header'>
              <h1 class='modal-title fs-5' id='exampleModalLabel'>
                Edit Information
              </h1>
              <button type='button' class='btn-close' data-bs-dismiss='modal' aria-label='Close'></button>
            </div>
            <div class='modal-body'>
              <div>
                <div class='form-floating mb-3'>
                  <input type='text' value={user.Name} class='form-control' id='floatingInput' placeholder='name@example.com' />
                  <label for='floatingInput'>Name</label>
                </div>
                <div class='form-floating mb-3'>
                  <input type='text' class='form-control' value={user.Phone} id='floatingPassword' placeholder='Phone' />
                  <label for='floatingPassword'>Phone</label>
                </div>
                <div class='form-floating mb-3'>
                  <input type='email' class='form-control' value={user.Email} id='floatingInput' placeholder='name@example.com' />
                  <label for='floatingInput'>Email address</label>
                </div>
                <div class='form-floating mb-3'>
                  <input type='password' class='form-control' value={user.password} id='floatingPassword' placeholder='Password' />
                  <label for='floatingPassword'>Password</label>
                </div>
                <div>
                  <a class='btn btn-warning text-white'>Submit</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  ) : (
    ''
  );
}
