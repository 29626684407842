import React, { useEffect, useState } from 'react';
import { USER, APIURL, CART, LANG, Loader, SetTitle } from '../../IWWJsCode';
import { Link, useSearchParams } from 'react-router-dom';
import SecLoader from '../Shared/Loaders/SecLoader';
import { useTranslation } from 'react-i18next';

export default function PaymentResult() {
  const [searchParams] = useSearchParams();
  const { t } = useTranslation();
  const { getUser } = USER();
  const { lang } = LANG();
  const { emptyCart } = CART();
  let [payment, setPayment] = useState();
  let [resultData, setResultData] = useState();
  let [message, setMessage] = useState({ success: null, message: null });
  const { loading, setLoading } = Loader();
  let data = searchParams.get('data');
  let id = searchParams.get('id');

  useEffect(() => {
    SetTitle('Payment Receipt');
  });
  useEffect(() => {
    if (data) getResultData(data);
  }, [data, lang]);

  useEffect(() => {
    if (id) getPaymentData(id);
  }, [id, lang]);

  function getResultData(d) {
    if (d && d.length) {
      setLoading(true);
      fetch(APIURL(`${lang}/payments/PaymentResult?data=${d}`))
        .then((res) => res.json())
        .then((json) => {
          if (json) {
            if (json.payment) {
              setPayment(json.payment);
              if (json.payment.paid && json.payment.isNewPayment) {
                emptyCart();
                getUser();
              }
            }

            if (json.paymentResponse) setResultData(json.paymentResponse);
            if (json.message) setMessage(json.message);
          }
          setLoading(false);
        });
    }
  }

  function getPaymentData(id) {
    if (id && id.length) {
      setLoading(true);
      fetch(APIURL(`${lang}/payments/PaymentTransactions?paymentId=${id}`))
        .then((res) => res.json())
        .then((json) => {
          if (json) {
            if (json.payment) {
              setPayment(json.payment);
              if (json.payment.paid && json.payment.isNewPayment) {
                emptyCart();
                getUser();
              }
            }

            // if (json.paymentResponse) setResultData(json.paymentResponse);
            if (json.message) setMessage(json.message);
          }
          console.log('json', json);
          setLoading(false);
        });
    }
  }

  return (
    <>
      {message && message.message ? (
        <div className={'alert p-1 rounded-0 text-center ' + (message.success ? 'alert-success' : 'alert-warning')}>
          <p className='m-0'>{message.message}</p>
        </div>
      ) : ''}
      <div className='container'>
        <div className='row'>
          {loading ? (
            <SecLoader type={'receipt'} />
          ) : (
            <>
              <div className='col-md-12'>
                <div class='card'>
                  {!loading && payment ? (
                    <div class='card-body mx-4'>
                      <div class='container'>
                        <p class='my-5 mx-5' style={{ fontSize: '30px' }}>
                          {payment.paid ? (
                            <>
                              <i class='far fa-check-circle text-success mx-2'></i>
                              {t('Thank for your purchase')}
                            </>
                          ) : (
                            <>
                              <i class='far fa-times-circle text-danger  mx-2'></i>
                              {t('No effective payment has been made')}
                              <span className='badge bg-light text-wrap text-primary'> {t('Please Check receipt again, if you completed the payment.')}</span>
                            </>
                          )}
                        </p>
                        <div class='row'>
                          <ul class='list-unstyled'>
                            <li class='text-black'>{payment.userName}</li>
                            {payment.transactions && payment.transactions.length
                              ? payment.transactions.map((tr) => (
                                <>
                                  <li class='text-muted mt-1'>
                                    <span class='text-black float-end'>{tr.date}</span>
                                    <span class='text-black'>
                                      {tr.merchant}
                                      {t('Invoice')} #{tr.invoiceNumber}
                                    </span>
                                    {tr.resultCode === 'Paid' ? <h5 className='text-success'>{t('SUCCESS')}</h5> : tr.resultCode === 'Failed' ? <h5 className='text-danger'>{t('FAILED')}</h5> : <h5 className='text-warning'>{tr.resultCode}</h5>}
                                    <hr />
                                  </li>
                                </>
                              ))
                              : ''}
                          </ul>
                          <hr />
                        </div>
                        {payment.items && payment.items.length
                          ? payment.items.map((it) => (
                            <>
                              <div class='row'>
                                <div class='col-xl-10'>
                                  <p>
                                    {it.type} - {it.name}
                                  </p>
                                </div>
                                <div class='col-xl-2'>
                                  <p class='float-end'>
                                    {it.price} - {payment.currency}
                                  </p>
                                </div>
                                <hr />
                              </div>
                            </>
                          ))
                          : ''}
                        <div class='row text-black'>
                          <hr style={{ border: '2px solid black' }} />
                          <div class='col-xl-12'>
                            <p class='float-end fw-bold'>
                              {t('Total')}: {payment.total} {payment.currency}
                            </p>
                          </div>
                          <div class='col-xl-12'>
                            <Link to='/my-courses' class='btn btn-outline-warning'>
                              {t('Go to Courses')}
                            </Link>
                          </div>
                          <hr style={{ border: '2px solid black' }} />
                        </div>
                      </div>
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      {/* <div className='d-none'>
        <h3>{t('Payment Result')}</h3>
        {resultData ? <p>{JSON.stringify(resultData)}</p> : ''}
      </div> */}
    </>
  );
}
