import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import axios from 'axios';
import { APIURL, LANG } from '../../IWWJsCode';
import { useApiData } from '../../Contexts/ApiDataContext';
import { useTranslation } from 'react-i18next';

export default function Footer() {
  let { universities, gettouch } = useApiData();
  let [Footer, SetFooter] = useState();

  const { t } = useTranslation();

  let { lang } = LANG();

  useEffect(() => {
    Getfooterdata();
  }, [lang]);

  async function Getfooterdata() {
    let { data } = await axios.get(APIURL(lang + '/web/footerAbout')).catch((err) => {
      console.error(err);
    });
    SetFooter(data);
  }

  return (
    <>
      <footer class='footer-section'>
        <div class='container'>
          <div class='footer-cta pt-5 pb-5'>
            <div class='row'>
              {gettouch && gettouch.address && gettouch.address.length ? (
                <div class='col-xl-4 col-md-4 mb-30'>
                  <div class='single-cta'>
                    <i class='fas fa-map-marker-alt'></i>
                    <div class='cta-text'>
                      <h4>{t('Address')}</h4>
                      <span>{gettouch.address}</span>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}

              {gettouch && gettouch.telephone && gettouch.telephone.length ? (
                <div class='col-xl-4 col-md-4 mb-30'>
                  <div class='single-cta'>
                    <i class='fas fa-phone-alt'></i>
                    <div class='cta-text'>
                      <h4>{t('Call us')}</h4>
                      <Link to={'tel:' + gettouch.telephone} class='text-decoration-none'>
                        <span>{gettouch.telephone}</span>
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}

              {gettouch && gettouch.whatsapp && gettouch.whatsapp.length ? (
                <div class='col-xl-4 col-md-4 mb-30'>
                  <div class='single-cta'>
                    <i class='fab fa-whatsapp'></i>
                    <div class='cta-text'>
                      <h4>{t('Chat with us')}</h4>
                      <a href={'https://wa.me/' + gettouch.whatsapp} target='_blank' className='text-decoration-none'>
                        <span>{gettouch.whatsapp}</span>
                      </a>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}

              {gettouch && gettouch.email && gettouch.email.length ? (
                <div class='col-xl-4 col-md-4 mb-30'>
                  <div class='single-cta'>
                    <i class='far fa-envelope-open'></i>
                    <div class='cta-text'>
                      <h4>{t('Mail us')}</h4>
                      <Link to={'mailto:' + gettouch.email} href='#' class='text-decoration-none'>
                        <span>{gettouch.email}</span>
                      </Link>
                    </div>
                  </div>
                </div>
              ) : (
                ''
              )}
            </div>
          </div>
          <div class='footer-content pt-5 pb-5'>
            <div class='row justify-content-center'>
              {Footer ? (
                <>
                  <div class='col-xl-4 col-lg-4 mb-50'>
                    <div class='footer-widget'>
                      <div class='footer-text'>
                        <div class='footer-widget-heading'>
                          <h3>{Footer.title}</h3>
                        </div>
                        <p
                          dangerouslySetInnerHTML={{
                            __html: Footer.htmlDescription,
                          }}></p>
                      </div>
                      {/* {Footer.image && Footer.image.length ? <img src={Footer.image} className='w-25' alt='' /> : ''} */}
                    </div>
                  </div>
                </>
              ) : (
                ''
              )}
              <div class='col-xl-4 col-lg-4 col-md-6 mb-30'>
                <div class='footer-widget'>
                  <div class='footer-widget-heading'>
                    <h3>{t('Useful Links')}</h3>
                  </div>
                  <ul>
                    <li>
                      <Link to='/home'>
                        <i className='fas fa-caret-right mx-1'></i> {t('Home')}
                      </Link>
                    </li>
                    <li>
                      <Link to='/about-us'>
                        <i className='fas fa-caret-right mx-1'></i> {t('About us')}
                      </Link>
                    </li>
                    <li>
                      <Link to={'/courses'}>
                        <i className='fas fa-caret-right mx-1'></i> {t('Courses')}
                      </Link>
                    </li>
                    <li>
                      <Link to={'/universities'}>
                        <i className='fas fa-caret-right mx-1'></i> {t('Universities')}
                      </Link>
                    </li>
                    <li>
                      <Link to='/policy'>
                        <i className='fas fa-caret-right mx-1'></i> {t('Usage Policy')}
                      </Link>
                    </li>
                    <li>
                      <Link to='/terms'>
                        <i className='fas fa-caret-right mx-1'></i>
                        {t('Terms and Conditions')}
                      </Link>
                    </li>

                    <li>
                      <Link to={'/contact-us'}>
                        <i className='fas fa-caret-right mx-1'></i> {t('Contact us')}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class='col-xl-4 col-lg-4 col-md-6 mb-30'>
                {gettouch && gettouch.social && gettouch.social.length > 0 ? (
                  <div class='footer-social-icon'>
                    <h3>{t('Follow us')}</h3>
                    <ul class='wrapper'>
                      {gettouch.social.map((el, i) => (
                        <li key={i} class='icon social-icon'>
                          <Link to={el.url} title={el.name} target='_blank'>
                            <span class='tooltip'>{el.name}</span>
                            <span>
                              <i className={el.faClass}></i>
                            </span>
                          </Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  ''
                )}
              </div>
            </div>
          </div>
        </div>
        <div class='copyright-area'>
          <div class='container'>
            <div class='row text-center'>
              <div class='col-sm-6'>
                <div class='copyright-text'>
                  <p>Copyright &copy; {new Date().getFullYear()} , All Right Reserved Smart Vision</p>
                </div>
              </div>
              <div class='col-sm-6'>
                <div class='copyright-text'>
                  <p>
                    <span class='fs-6 mt-2'>
                      Powered By:
                      <a class='text-decoration-none fs-6' href='https://iwgt.ae/' target='_blank'>
                        IDEA WEB GLOBAL TECHNOLOGY L.L.C
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
