import { LANG, CART } from '../../../IWWJsCode';
import { useTranslation } from 'react-i18next';

export default function CartButton({ id, price, type, paymentType }) {
  let { currency, financialPrice } = LANG();
  let { cartItems, addItem } = CART();
  const InCart = cartItems.some((i) => i.id === id && i.type === type);

  const { t } = useTranslation();
  return (
    <>
      <button
        className={'btn ' + (paymentType === 'Payment' ? 'btn-warning text-white' : 'btn-outline-warning mx-2')}
        type='button'
        disabled={InCart}
        onClick={(e) => {
          e.stopPropagation();
          addItem({ id: id, type: type, paymentType });
        }}>
        <i class='fas fa-cart-plus mx-1'></i>
        {InCart ? (
          <>
            <span className='mx-1'>{t('Already in the cart')}</span>
          </>
        ) : (
          <>
            <span className='mx-1'>
              {t('Add')} {t(type === 'CourseInstallment' ? 'Installment' : type)}
            </span>
          </>
        )}
        {paymentType === 'Installment' ? (
          <>
            <i class='fas fa-calendar-alt mx-1'></i> {financialPrice(price)} {currency}
          </>
        ) : (
          ''
        )}
      </button>
    </>
  );
}
