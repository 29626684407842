/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { LANG, APIURL, Loader, USER, SetTitle } from '../../IWWJsCode';
import CourseBox from './Partials/_CourseBox';
import { useTranslation } from 'react-i18next';
import { useApiData } from '../../Contexts/ApiDataContext';

export default function Courses() {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { lang } = LANG();
  const { user } = USER();
  const { loading, setLoading } = Loader();
  const [courses, setCourses] = useState(null);
  const { universities } = useApiData();

  // get id from url params
  let [University, SetUniversity] = useState();
  let [Colleges, SetColleges] = useState([]);
  let [Majors, SetMajors] = useState([]);
  let [Instructors, SetInstructors] = useState([]);

  //c: '', m: '', i: '', txt: ''
  // let { universityId } = useParams();
  let [searchParams, setSearchParams] = useSearchParams({});
  let [_universityId, Set_universityId] = useState(searchParams.get('u'));
  let [searchText, SetSearchText] = useState(searchParams.get('s'));
  let [collegeId, SetCollegeId] = useState(searchParams.get('c'));
  let [majorId, SetMajorId] = useState(searchParams.get('m'));
  let [instructorId, SetInstructorId] = useState(searchParams.get('i'));

  useEffect(() => {
    SetSearchText(searchParams.get('s'));
    CheckUser();
    SetTitle('Courses Filter');
    // if (universityId)
    // Set_universityId(universityId);
  });


  function CheckUser() {
    if (!user)
      navigate('/login?rurl=/Courses');
  }
  
  useEffect(() => {
    getUniversity();
  }, [_universityId]);

  useEffect(() => {
    getCourses();
  }, [lang, searchText]);

  useEffect(() => {
    getColleges();
    getMajors();
    getInstructors();
    window.scrollTo(0, 0);
  }, [lang]);


  async function getCourses() {
    setLoading(true);
    setCourses(null);
    //?searchTxt=${searchText}&universityId=${_universityId}&collegeId=${collegeId}&majorId=${majorId}&instructorId=${instructorId}
    fetch(APIURL(lang + `/Courses/get`),
      {
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify({ searchTxt: (searchText ? searchText : null), universityId: (_universityId ? _universityId : null), collegeId: (collegeId ? collegeId : null), majorId: (majorId ? majorId : null), instructorId: (instructorId && instructorId != undefined ? instructorId : null) })
      }
    )
      .then((res) => res.json())
      .then((json) => {
        setCourses(json);
        setLoading(false);
      });
  }

  async function getUniversity() {
    SetUniversity();
    if (_universityId && _universityId != '') {
      fetch(APIURL(lang + `/Universities/byId?id=${_universityId}`))
        .then((res) => res.json())
        .then((json) => {
          SetUniversity(json);
        });
    }
  }

  async function getColleges() {
    //?id=${_universityId}
    fetch(APIURL(`${lang}/Universities/Colleges`))
      .then((res) => res.json())
      .then((json) => {
        SetColleges(json);
      });
  }

  async function getMajors() {
    SetMajors([{ id: '', name: 'loading..' }]);
    //?id=${collegeId}&uid=${_universityId}
    fetch(APIURL(`${lang}/Universities/Majors`))
      .then((res) => res.json())
      .then((json) => {
        SetMajors(json);
      });
  }

  async function getInstructors() {
    SetInstructors([{ id: '', name: 'loading..' }]);
    //?universityId=${_universityId}&collegeId=${collegeId}&majorId=${majorId}
    fetch(APIURL(`${lang}/Courses/instructors`))
      .then((res) => res.json())
      .then((json) => {
        SetInstructors(json);
      });

  }

  function clearFilter() {
    setSearchParams((prev) => {
      prev.delete('s');
      prev.delete('c');
      prev.delete('m');
      prev.delete('i');
      prev.delete('u');
      return prev;
    }, { replace: true });

    if (instructorId)
      SetInstructorId('');
    if (collegeId)
      SetCollegeId('');
    if (majorId)
      SetMajorId('');
    if (searchText)
      SetSearchText('');
    if (_universityId)
      Set_universityId('');
  }
  function updateSearchParams(key, value) {
    setSearchParams((prev) => { if (value) prev.set(key, value); else prev.delete(key); return prev; }, { replace: true });
  }
  function onSubmitFilter() {
    updateSearchParams('c', collegeId);
    updateSearchParams('m', majorId);
    updateSearchParams('i', instructorId);
    updateSearchParams('u', _universityId);

    getCourses();
  }


  return (
    <>
      <section class='mt-3'>
        <div class='container'>
          <div class='row'>
            <div class='col-sm-12'>
              <div class='about-text my-4'>
                <h3>{t('Search Courses')}</h3>
              </div>
            </div>
            <div class='col-sm-10 m-auto'>
              <div className='position-sticky top-0'>
                {University ? (
                  <>
                    <div className='section-head text-center'>
                      {University.image ? <img src={University.image} className='h-auto' alt={University.name} width={'150px'} /> : ''}
                      <h3>{University.name}</h3>
                    </div>
                  </>
                ) : (
                  ''
                )}
              </div>
              <div class='card border-0 bg-wihte p-3'>
                <form>
                  <div class='d-md-flex'>
                    <div className='form-floating w-100 m-1'>
                      <select
                        id='instructorId'
                        className={'form-select mb-2'}
                        value={instructorId}
                        onChange={(e) => {
                          SetInstructorId(e.target.value);
                        }}>
                        <option selected value=''>
                          {t('All')}
                        </option>
                        {Instructors &&
                          Instructors.length &&
                          Instructors.map((el) => (
                            <option key={el.id} value={el.id}>
                              {el.name}
                            </option>
                          ))}
                      </select>
                      <label htmlFor='instructorId'>{t('Instructor')}</label>
                    </div>
                    <div className='form-floating w-100 m-1'>
                      <select
                        id='_universityId'
                        className='form-select mb-2'
                        value={_universityId}
                        onChange={(e) => {
                          Set_universityId(e.target.value);
                        }}>
                        <option selected value=''>
                          {t('All')}
                        </option>
                        {universities &&
                          universities.length &&
                          universities.map((el) => (
                            <option key={el.id} value={el.id}>
                              {el.name}
                            </option>
                          ))}
                      </select>
                      <label for='_universityId'>{t('University')}</label>
                    </div>
                  </div>
                  <div class='d-md-flex'>
                    <div className='form-floating w-100 m-1'>
                      <select
                        id='collegeId'
                        className={'form-select mb-2'}
                        value={collegeId}
                        onChange={(e) => {
                          SetCollegeId(e.target.value);
                        }}>
                        <option selected value=''>
                          {t('All')}
                        </option>
                        {Colleges &&
                          Colleges.length &&
                          Colleges.map((el) => (
                            <option key={el.id} value={el.id}>
                              {el.name}
                            </option>
                          ))}
                      </select>
                      <label htmlFor='collegeId'>{t('College')}</label>
                    </div>
                    <div className='form-floating w-100 m-1'>
                      <select
                        id='majorId'
                        className={'form-select mb-2'}
                        value={majorId}
                        onChange={(e) => {
                          SetMajorId(e.target.value);
                        }}>
                        <option selected value=''>
                          {t('All')}
                        </option>
                        {Majors &&
                          Majors.length &&
                          Majors.map((el) => (
                            <option key={el.id} value={el.id}>
                              {el.name}
                            </option>
                          ))}
                      </select>
                      <label htmlFor='majorId'>{t('Major')}</label>
                    </div>
                  </div>
                  <div class='text-center'>
                    <a
                      onClick={(e) => {
                        clearFilter();
                      }}
                      class='btn btn-light btn-sm float-end mx-1'>
                      <i className='fas fa-times mx-1'></i>
                    </a>
                    <a
                      onClick={(e) => {
                        onSubmitFilter();
                      }}
                      class='btn btn-warning text-white px-5 py-2'>
                      {t('Search')}
                    </a>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class='bg-sec-course mt-5 p-3' style={{ minHeight: '50vh' }}>
        <div class='container'>
          <div class='row justify-content-center g-3'>
            <div class='col-sm-12'>
              <div class='mb-4'>
                <h6 class='rounded-pill bg-pill d-inline px-3 py-1 text-white'>{t('Course List')}</h6>
              </div>
            </div>
            {loading && !University ? (
              <>
                {/* <div className='w-100 text-center'>
                  <i className='fas fa-circle-notch text-warning fa-spin'></i>
                </div> */}
              </>
            ) : (
              ''
            )}
            {courses && courses.length > 0 ? (
              courses.map((course, idx) => (
                <div className='col-lg-3 col-md-4 col-sm-6' key={idx}>
                  <CourseBox course={course} />
                </div>
              ))
            ) : (
              <>
                {!loading && (!courses || courses.length) === 0 ? (
                  <h3 className='text-muted text-center text-secondary'>
                    <i className='far fa-images mx-2'></i>
                    {t('No courses found')}
                  </h3>
                ) : (
                  ''
                )}
              </>
            )}
          </div>
        </div>
      </section>
    </>
  );
}
